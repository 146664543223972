import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import {Image} from 'react-native';
import Iframe from 'react-iframe';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{borderRadius: "25px", WebkitBorderRadius: "25px", overflow: "hidden", border: '1px solid black', margin: "1px" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  
  const windowHeight = window.innerHeight-67;
  const windowWidth = window.innerWidth-4;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: windowWidth, height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'inline-flex', background: 'rgba(27,158,145,0.9)', width: '100%', top: '0px', margin: '1px', borderRadius: '25px' }}>
      <Image
        style={{height: '50px', width: '50px', margin: '2px' }}
        source={'./logo192.png'}
        />
        <Tabs value={value} onChange={handleChange} aria-label="General Tabs" scrollButtons="auto" variant="scrollable" >
          <Tab label="RocketChat" {...a11yProps(0)} />
          <Tab label="Snake" {...a11yProps(1)} />
          <Tab label="Xwiki" {...a11yProps(2)} />
          <Tab label="Nextcloud" {...a11yProps(3)} />
          <Tab label="Camunda" {...a11yProps(4)} />
          <Tab label="Jitsi" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
      <Iframe 
        url="https://rocketchat.grishin.tech"
        width="100%"
        height={windowHeight}
        id="rocketChat"/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Iframe 
        url="https://grishin.tech/snake"
        width="100%"
        height={windowHeight}
        id="snake"/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Iframe url="https://grishin.tech/xwiki"
        width="100%"
        height={windowHeight}
        id="xwiki"/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Iframe url="https://nextcloud.grishin.tech"
        width="100%"
        height={windowHeight}
        id="nextCloud"/>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Iframe url="https://grishin.tech/camunda"
        width="100%"
        height={windowHeight}
        id="nextCloud"/>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Iframe url="https://jitsi.grishin.tech"
        width="100%"
        height={windowHeight}
        id="jitsi"/>
      </TabPanel>
    </Box>
  );
}
